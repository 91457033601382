import { useEffect, useRef, useState } from "preact/hooks"

import { Message } from "./message"
import { Modal } from "./modal"
import { Step } from "./step"

import { qs } from "../util/dom"
import metrics from "../util/metrics"

const gettext = window.gettext

const headerEl = qs("header")
const honeypotEl = qs("input[name=important_inputfield]")
const loadingEl = qs("#loading")

const toggleHoneypotField = (hide = true) => {
  if (hide && !honeypotEl.classList.contains("hide"))
    honeypotEl.classList.add("hide")
  else if (!hide && honeypotEl.classList.contains("hide"))
    honeypotEl.classList.remove("hide")
}

export const Form = (props) => {
  const [step, setStep] = useState(0)
  const [stepClass, setStepClass] = useState("from-right")
  const [storedValues, setStoredValues] = useState([])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [modalData, setModalData] = useState({}) // shows legal hints and more info about specific terms
  const [legalModalShown, setLegalModalShown] = useState([]) // keeps track of already shown legal hints
  const [summaryActive, setSummaryActive] = useState(false)
  const [messageData, setMessageData] = useState({})
  const [help, setHelp] = useState(undefined) // form input helper, shows required inputs
  const formRef = useRef()

  /**
   * Animate loading screen on load
   *
   * @param {Boolean} loadingValue
   */
  const toggleLoadingScreen = (loadingValue) => {
    if (!loadingValue) {
      loadingEl.classList.add("collapse")
      document.body.classList.remove("loading")
      setLoading(loadingValue)
    } else {
      loadingEl.classList.remove("collapse")
      document.body.classList.add("loading")
      setLoading(loadingValue)
    }
  }

  const popstateEventHandler = (e) => {
    if (e.state !== null) {
      showStep(0, e.state.step, false)
    } else window.location = ""
  }

  /**
   * Load endpoint data
   */
  if (!loading && !data.steps) {
    setLoading(true)
    window.addEventListener("popstate", popstateEventHandler)
    props.ep.getData("steps").then((epData) => {
      setData(epData)
      console.log(epData)
      toggleLoadingScreen(false)
    })
  }

  /**
   * - scroll to top on step progression
   */
  useEffect(() => {
    toggleHoneypotField(![6, 8].includes(step))
    if (data.steps) metrics(data.steps[step].slug)
    const marginTop = headerEl.offsetHeight
    formRef.current.style = `margin-top: ${marginTop}px`
    window.scrollTo(0, 0)
  }, [step])

  /**
   * Switch to a step id or a relative position (c) in step array
   */
  const showStep = (c = 0, id = 0, push = true) => {
    id = Number.isInteger(id) ? id : data.steps.findIndex((s) => s.slug === id)
    const nextStep = Number(c) !== 0 ? step + Number(c) : Number(id)

    switch (nextStep) {
      case 5:
        setSummaryActive(true)
        break
      case 7:
        window.removeEventListener("popstate", popstateEventHandler)
        window.addEventListener("popstate", (e) => {
          if (e.state === null || e.state.step < 7) {
            window.location = ""
          } else {
            showStep(0, Number(e.state.step), false)
          }
        })
        break
      default:
        break
    }

    setStepClass(step > nextStep ? "from-left" : "from-right")
    if (push && nextStep > 0) {
      history.pushState({ step: nextStep }, "Meldetool Reporting")
    }
    setStep(nextStep)
    window.scrollTo(0, 0)
  }

  /**
   * Find identical and similar input values and toggle or just remove according to single and multiple choices
   * Similar values have to be toggled if input groups only should have one value (just the singular case)
   * Identical values have to be removed (same element got clicked twice) (both cases)
   *
   * Specific values are removed if called by repective change events
   *
   * @param {Object} inputObj
   * @param {Boolean} deleteSimilar
   * @param {Boolean} removeSpecific
   */
  const toggleValue = (
    inputObj,
    deleteSimilar = false,
    removeSpecific = false,
  ) => {
    toggleModal(inputObj)

    // fix array identity check when preact considers component updates
    let newValues = [...storedValues]

    const i_identical = newValues.findIndex(
      (o) => JSON.stringify(o) === JSON.stringify(inputObj),
    )

    if (i_identical >= 0) {
      newValues.splice(i_identical, 1)
    } else {
      if (deleteSimilar) {
        newValues = newValues.filter((o) => o.name !== inputObj.name)
      }
      if (removeSpecific) {
        newValues = newValues.filter((o) => !removeSpecific.includes(o.name))
      }
      newValues.push(inputObj)
    }

    setStoredValues(newValues)
  }

  /**
   * Directly remove values with certain name from the store
   *
   * @param {String} name
   */
  const removeValue = (name) => {
    let newValues = [...storedValues]
    newValues = newValues.filter((o) => o.name !== name)
    setStoredValues(newValues)
  }

  /**
   * @param {Object} valObj
   * @returns {Object|Boolean}
   */
  const inFormValues = (valObj) => {
    const match = storedValues.find((o) => {
      return (
        (valObj.id ? o.step === valObj.id : true) &&
        (valObj.name ? o.name === valObj.name : true) &&
        (valObj.value ? o.value === valObj.value : true)
      )
    })
    return match
  }

  /**
   * Show legal modal if toggled by any values in store
   */
  const toggleModal = ({ name, value }) => {
    if (
      data.modals &&
      !inFormValues({ name: "reporter_type", value: "observer" })
    ) {
      const newModal = data.modals.find((modal) =>
        modal.toggles.some(
          (toggle) => toggle.name === name && toggle.value === value,
        ),
      )
      if (newModal && !legalModalShown.includes(`${name}-${value}`)) {
        setModalData(newModal)
        const newValues = [...legalModalShown]
        newValues.push(`${name}-${value}`)
        setLegalModalShown(newValues)
      }
    }
  }

  /**
   * Determines whether any or any specific required input is missing.
   */
  const requirementsMet = (name = undefined) => {
    const requirements = data.steps[step]?.requirements

    let requirementsAreMet = true
    if (name && requirements) {
      requirementsAreMet =
        requirements.includes(name) &&
        storedValues.some((value) => value.name === name)
    } else if (requirements) {
      requirementsAreMet = data.steps[step].requirements.every(
        (requirement) => {
          return storedValues.some((value) => value.name === requirement)
        },
      )
    }
    return requirementsAreMet
  }

  /**
   * Outline required fields
   */
  const showHelp = () => {
    if (!requirementsMet()) {
      setHelp(true)
      setTimeout(() => {
        setHelp(false)
      }, 5000)
    }
  }

  /**
   * Handle form submission
   */
  const onSubmit = async (
    valueStore = storedValues,
    contactForm = false,
    redirect = false,
  ) => {
    const endpoint = contactForm ? props.contactEp : props.ep

    toggleLoadingScreen(true)

    const formData = new FormData()

    // add input values
    valueStore.map((o) => {
      formData.append(o.name, o.value)
    })

    // add honeypot value
    const hpName = "important_inputfield"
    const hpValue = qs(`input[name=${hpName}]`).value
    formData.append(hpName, hpValue)

    // submit formData
    const response = await endpoint.postData(formData)

    if (response.valid) {
      if (contactForm) {
        redirect && showStep(0, Number(redirect))
        setMessageData({
          message: gettext("Consultation request sent!"),
          severity: "ok",
        })
      } else {
        showStep(1)
      }
    } else handleValidationErrors(response)

    setModalData({})
    toggleLoadingScreen(false)
  }

  const handleValidationErrors = (response) => {
    console.log("errorResponseObject", response)
    const message = response.message || gettext("Something went wrong")
    setMessageData({
      message,
      severity: "error",
    })
  }

  return (
    <form ref={formRef} data-help={help}>
      {messageData.message && <Message data={messageData} />}
      {!loading && data.steps && (
        <Step
          class={stepClass}
          key={step}
          stepId={step}
          step={data.steps[step]}
          storedValues={storedValues}
          toggleValue={toggleValue}
          showStep={showStep}
          requirementsMet={requirementsMet}
          setModalData={setModalData}
          inFormValues={inFormValues}
          removeValue={removeValue}
          contactEp={props.contactEp}
          onSubmit={onSubmit}
          showHelp={showHelp}
          openHint={setModalData}
          summaryActive={summaryActive}
          ep={props.ep}
        />
      )}
      {modalData.contents && (
        <Modal {...modalData} setModalData={setModalData} />
      )}
    </form>
  )
}
